import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { CategoryList } from '../components/category-list'
import { Paper } from '../components/paper'
import { Stack } from '../components/stack'
import { WelcomeBanner } from '../components/welcome-banner'
import { Container } from '../components/container'

const IndexPage: React.FC<Props> = () => {
  return (
    <Layout>
      <SEO />
      <Container>
        <Stack>
          <WelcomeBanner></WelcomeBanner>
          <CategoryList></CategoryList>
        </Stack>
      </Container>
    </Layout>
  )
}

interface Props {
  data: {
    api: {
      categories: {
        _id: string
        name: string
        slug: string
        image: string
      }[]
    }
  }
}

export default IndexPage
