import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
// @ts-ignore
import { createCategoryPath } from '../../shared/shared'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import Category from '../templates/category'
import { PositionProperty, Position } from 'csstype'
import Color from 'color'
import { FixedImage } from '../templates/technology'

type Category = {
  _id: string
  name: string
  slug: string
  localImage: FixedImage
  color: string
}

interface Query {
  api: {
    categories: Category[]
  }
}

const useListStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gap: theme.spacing(6),
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    listStyle: 'none',
  },
}))

export const CategoryList: React.FC = () => {
  const {
    api: { categories },
  } = useStaticQuery<Query>(graphql`
    query CategoryListQuery {
      api {
        categories {
          _id
          name
          slug
          image
          localImage {
            childImageSharp {
              fixed(grayscale: true) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          color
        }
      }
    }
  `)
  const styles = useListStyles()

  return (
    <ul className={styles.root}>
      {categories.map((category, i) => (
        <CategoryTile key={category._id} category={category} index={i}></CategoryTile>
      ))}
    </ul>
  )
}

interface CategoryTileProps {
  category: Category
  index: number
}

export const CategoryTile: React.FC<CategoryTileProps> = ({ category, index }) => {
  const styles = useStyles({ color: category.color })

  return (
    <li key={category._id}>
      <Link to={createCategoryPath(category.slug)} className={styles.link}>
        <span className={styles.overlay}>{category.name}</span>
        <img src={category.localImage.childImageSharp.fixed.src} className={styles.image} />
      </Link>
    </li>
  )
}

const useStyles = makeStyles<Theme, { color: string }>(theme => ({
  link: props => ({
    display: 'block',
    position: 'relative' as PositionProperty,
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: `0 2px 1px rgba(0,0,0,0.05), 0 4px 2px rgba(0,0,0,0.05), 0 8px 4px rgba(0,0,0,0.05), 0 16px 8px rgba(0,0,0,0.05), 0 32px 16px rgba(0,0,0,0.05)`,
    transition: 'all 0.2s',
    '&:hover, &:focus': {
      transform: 'scale(1.05)',
      '& $overlay': {
        background: Color(props.color)
          .fade(0.1)
          .toString(),
      },
      '& $image': {
        transform: 'scale(1.1)  rotate(3deg)',
      },
    },
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 1,
      height: 0,
      paddingBottom: '75%',
      verticalAlign: 'top',
    },
  }),
  image: props => ({
    position: 'absolute' as PositionProperty,
    transition: 'transform 0.55s',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover' as any,
  }),
  overlay: props => ({
    position: 'absolute' as PositionProperty,
    padding: theme.spacing(2),
    textAlign: 'center' as any,
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: Color(props.color)
      .fade(0.2)
      .toString(),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.h5.fontSize,
    transition: 'background 0.25s',
    color: 'white',
    fontWeight: 600,
    textShadow: '0 0 5px rgba(43, 74, 83, 0.7686274509803922)',
    '@media(max-width: 500px)': {
      fontSize: '1.25rem',
    },
  }),
}))
