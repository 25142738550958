import { useStaticQuery, graphql } from 'gatsby'

interface Query {
  api: {
    texts: {
      key: string
      value: string
    }[]
  }
}

export function useStaticText(key: string | string[]) {
  const {
    api: { texts },
  } = useStaticQuery<Query>(graphql`
    query getTexts {
      api {
        texts {
          key
          value
        }
      }
    }
  `)

  return texts.find(text => text.key === key)?.value
}
