import * as React from 'react'
import { Paper } from './paper'
import { makeStyles } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'
import { useStaticText } from '../hooks/useStaticText'

export const WelcomeBanner: React.FC = () => {
  const styles = useStyles({})

  const text = useStaticText('welcome-banner')

  return (
    <Paper className={styles.root}>
      <div className={styles.emoji}>👋</div>
      <p className={styles.text}>{text}</p>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gridGap: theme.spacing(4),
    borderRadius: 10,
  },
  emoji: {
    fontSize: theme.typography.h3.fontSize,
  },
  text: {
    lineHeight: theme.typography.body1.lineHeight,
    fontSize: theme.typography.body1.fontSize,
  },
}))
